.box img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  background: transparent;
}

h1 {
  color: #f3f3f3;
}

.grid {
  display: inline-flex;
  flex-wrap: wrap;
  .detail-modal {
    background-color: #000;
    border: none;
    cursor: pointer;
  }
}
.item {
  background-color: rgba(0, 0, 0, 0.92);
  p {
    color: #cccccc;
    font-weight: 500;
    text-align: left;
  }
}
.item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.92);
}

@media screen and (min-width: 1400px) {
  .desktop-grid {
      padding-top: 55px;
      display: grid;
      height:calc((100vh - 55px));
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
      "video video video cars apartments restaurants"
      "video video video cars apartments restaurants"
      "video video video funny jobs music"
      "video video video funny jobs music"
      "hairMakeup it execSupport sports fashions handyWork"
      "hairMakeup it execSupport sports fashions handyWork"
      "photo bgeMovies taxi social live post"
      "photo bgeMovies taxi social live post";
    .item {
      width: auto;
      height: auto;
    }
  }
  
  .desktop-grid .item:nth-child(1)  { grid-area: video }

  .desktop-grid .item:nth-child(2)  { grid-area: cars; }
  .desktop-grid .item:nth-child(3)  { grid-area: apartments; }
  .desktop-grid .item:nth-child(4)  { grid-area: restaurants; }

  .desktop-grid .item:nth-child(5)  { grid-area: funny; }
  .desktop-grid .item:nth-child(6)  { grid-area: jobs; }
  .desktop-grid .item:nth-child(7)  { grid-area: music; }

  .desktop-grid .item:nth-child(8)  { grid-area: hairMakeup; }
  .desktop-grid .item:nth-child(9)  { grid-area: it; }
  .desktop-grid .item:nth-child(10) { grid-area: execSupport; }
  .desktop-grid .item:nth-child(11)  { grid-area: sports; }
  .desktop-grid .item:nth-child(12)  { grid-area: fashions; }
  .desktop-grid .item:nth-child(13)  { grid-area: handyWork; }
  
  .desktop-grid .item:nth-child(14)  { grid-area: photo; }  
  .desktop-grid .item:nth-child(15)  { grid-area: bgeMovies; }
  .desktop-grid .item:nth-child(16) { grid-area: taxi; }
  .desktop-grid .item:nth-child(17)  { grid-area: social; }
  .desktop-grid .item:nth-child(18)  { grid-area: live; }
  .desktop-grid .item:nth-child(19)  { grid-area: post; }
}