.text-center {
  text-align: center;
}
.error{
  font-size: 14px !important;
  color: #fff !important;
  margin-top: -25px;
  margin-bottom: 10px;
}

.form_ctm {
  display: flex;
  background: url("../../Assets/coach-photo.png");
  background-position: top center;
  background-size: cover;
  backdrop-filter: blur(10px);
  min-height: 110vh;
  max-height: 120vh;
  flex-direction: column;
  align-items: center;
  .form_input_group {
    label {
      text-align: start;
      width: 100%;
      display: inline-block;
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 10px;
    }
  }
  .logo {
    margin-bottom: 2em;
  }
  form {
    width: 60%;
    background: linear-gradient(
      90deg,
      rgba(196, 5, 9, 0.5) 0%,
      rgba(196, 46, 5, 0.5) 35%,
      rgba(255, 119, 0, 0.5) 100%
    );
    backdrop-filter: blur(10px);
    padding: 24px;
    border-radius: 13px;
  }
  input {
    color: #020202;
    width: 100%;
    margin-bottom: 1.5em;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    padding-left: 15px;
    border: none;
    box-shadow: none;
  }
  input:focus-visible {
    border: none;
    outline: none;
  }
  select {
    font-size: 16px;
    margin-bottom: 1.5em;
    padding-left: 15px;
    color: #020202;
    border-radius: 8px;
    width: 100%;
    height: 40px;
  }
  .form_input_group {
    div {
      display: flex;
      flex-direction: column;
      row-gap: 0.2em;
      align-items: flex-start;
    }
  }
  .form_progress_bar {
    background-color: #fff;
    width: 100%;
    height: 10px;
    margin-bottom: 1em;
    .form_progress {
      background-color: #ff7700;
      height: 100%;
      max-width: 100%;
    }
  }
  .call-to-action_form {
    font-size: 20px;
    border-radius: 13px;
    background: #c40509;
    background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%);
    border-color: #4444;
    height: 46px;
    font-weight: 700;
  }
  .call-to-action_form:hover {
    color: #fff !important;
    border-color: #4444 !important;
    background: linear-gradient(
      90deg,
      #c40509 0%,
      #c42e05 35%,
      #ff7700 100%
    ) !important;
  }
  .call-to-action_form:focus {
    color: #fff !important;
    border-color: #4444 !important;
    background: linear-gradient(
      90deg,
      #c40509 0%,
      #c42e05 35%,
      #ff7700 100%
    ) !important;
  }
  .form_button_group {
    display: flex;
    justify-content: space-between;
  }
  .call-to-action_form:disabled {
    background: #808080 !important;
  }
  .logo {
    margin-top: 25px !important;
  }
}
.justify-content-end{
  justify-content: end !important; 
 }
@media (max-width: 768px) {
  .form_ctm {
    height: 100vh;
    form {
      width: 90%;
    }
  }
}
