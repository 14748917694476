.box img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    background: transparent;
  }

  h1 {
    color: #f3f3f3;
  }
  
  .mobile-grid {
    display: inline-flex;
    flex-wrap: wrap;
    animation-duration: 2000ms;
    animation-name: fadeIn;  
    overflow: hidden;
    padding-top: 55px;
    .detail-modal {
      display: block;
      background-color: #000;
      border: none;
      cursor: pointer;
    }
    &.fadeOut {
      animation-duration: 1000ms;
      animation-name: fadeAway;  
      z-index: -1;
      opacity: 0;
      position: absolute;
    }
  }
  .item {
    background-color: rgba(0, 0, 0, 0.69);
      border: #000 solid 1px;
    p {
      color: #cccccc;
      font-weight: 500;
      text-align: left;
    }
  }
  .item:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.7);
    }

  @keyframes fadeAway {
    0% {
      opacity: 1;
      z-index: 1;
    }
    100% {
      opacity: 0;
      z-index: -1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      z-index: -1;
    }
    100% {
      opacity: 1;
      z-index: 1;
      display: none;

    }
  }
    @media screen and (min-width: 750px) {
  
  
    }
  
    @media screen and (min-width: 1400px) {
  
    }

    @media (min-width:320px)  { 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .mobile-grid {
        .item {
            border-radius: 6px;
            width: calc(100% / 3);
            height: calc((100vh - 55px) / 6);
        }  
      }
    }
    @media (min-width:481px)  { 
      /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
      .mobile-grid {
        .item {
            width: calc(100% / 6);
            height: calc((100vh - 55px) / 3);
        }    
      } 
    }
    @media (min-width:641px)  { 
      /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
     /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
     .mobile-grid {
      .item {
          width: calc(100% / 6);
          height: calc((100vh - 55px) / 3);
      }
    } 
  }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
