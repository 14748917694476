.header {
    position: heade;
    width: 100%;
    background-color: #020202;
    height: 55px;
    z-index: 5;

    span.bge {
        position: relative;
        letter-spacing: -1px;
        font-size: 2rem;
        color: #6e1b6e;
        top: .2rem;
        left: 1rem;
        span.nyc {
            letter-spacing: -1px;
            color: #ffffff;
        }
    }   
}
