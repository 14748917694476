.stripe-btns {
  text-align: right;
  input {
    color: black;
    margin-left: .5rem;
    margin-right: .5rem;
    text-align: center;
  }
  .buy {
    margin-right: 1rem;
  }
  .quantity-setter {
    padding-bottom: 1rem;
    text-align: right;
    button {
      color: black;
      background-color: #f3f3f3;
      border: none;
    }
  }
}