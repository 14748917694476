@media (min-width:320px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 
    .mobile-list-grid {
      .item {
        position: relative;
        width: 100%;
        height: 255px;
        margin-bottom: 1rem;
        right: 0px;
        img {
          width: 100%;
          height: auto;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        
        }

        .footer {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: .8rem;
          padding-right: 0px;
          justify-content: flex-end;
          text-align: right;
          button {
            margin-right: 1rem;

            &.back {
              margin-right: .59rem;
            }
          }
        }
    }  
  }
}
