.mobile-slider {
  transition: all 255ms ease-in-out;
  height: 0;
  width: 100%;
  background: linear-gradient(33deg, rgba(0,0,0,0.9640231092436975) 0%, rgba(90,27,110,1) 46%, rgba(27,30,110,1) 100%);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 6;
  opacity: 0;
  overflow: hidden;
  h3 {
    color: #f3f3f3;
    display: none;
  }
  h1 {
    color: #f2f2f2;

  }
  img {
    width: 100%;
  }
  &.showSlider {
    transition: all 255ms ease-in-out;
    padding: 1rem;

    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 1;
    height: 100vh;

    img {
      width: 100%;
    }
  }
  .footer {
    position: relative;
   left: 0px;
    width: 100%;
    padding: .3rem;
    ;
    display: inline-flex;
    justify-content: center;
    button {
      margin-right: 1rem;
    }
  } 
}