.w50 {
  width: 100%;
}
.w100 {
  width: 100%;
}
.row {
  display: inline-flex
}
.md {
  display: block;
}
.container {
  padding: 1rem;
}

.bottom {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

@media screen and (min-width: 750px) {
  .container {
    padding: 3rem;
  }
  .md {
    display: inline-flex;
    .w50 {
      width: 50%;
    }
  }
}

@media screen and (min-width: 1400px) {
}

@media (min-width:320px)  { 
/* smartphones, iPhone, portrait 480x320 phones */ 

}
@media (min-width:481px)  { 
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  
}
@media (min-width:641px)  { 
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
