.dropemStyles {
  width: 100%;
  li {
    color: #ffffff;
    list-style: none;
  }
  p {
    color: #ffffff;
  }
  ul{
    padding: 1rem;
  }
  .price {
    li {
      text-align: left;
      font-size: 2.3rem;
      line-height: 2.1rem;
      @media screen and (min-width: 750px) {
        text-align: center;
      }
    }
  }
  .scope {
    li {
      font-size: 1.3rem;
    }
  }
  .workout-plans {
    padding: 1rem 2rem 1rem 2rem;
    .ant-tabs-tabpane {
      height: 700px;
      overflow: auto;
    }
    h1 {
      margin-bottom: 0px;
      line-height: 2rem;
    }
    .ant-tabs-tab {
      color: #fff
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #c42e05;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: #c42e05;
      pointer-events: none;
   }
   .w100 {
    display: inline-grid;
    width: 100%;
    overflow: auto;
    height: auto;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: 
      "main"
      "form";   
    .col1 {
      grid-area: main;
    }
    .col2 {
      grid-area: form;
    }
    @media screen and (min-width: 750px) {
      column-gap: 10px;
      grid-template-columns: 70% 29%;
      grid-template-rows: 100% 100%;
      grid-template-areas: 
        "main form"
    }
    @media only screen 
    and (min-width: 1024px) 
    and (max-height: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas: 
        "main"
        "form";
    }
    @media only screen 
    and (min-width: 768px) 
    and (max-height: 10246px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas: 
        "main"
        "form";
    }
  }
  }
}