.mobile-scroll {
  display: none;
  padding-top: 55px;
  z-index: -1;
  width: 100%;
  opacity: 0;
  &.show {
    animation-duration: 2000ms;
    animation-name: fadeIn;
    opacity: 1;
    z-index: 1;
    display: block;
  }
  &.showSlider {
    overflow: hidden;
  }
  @keyframes fadeIn {
    0% {
      display: block;
      opacity: 0;
      z-index: -1;
    }
    100% {
      opacity: 1;
      z-index: 1;
    }
  }

  .mobile-list-grid {
    padding: 1rem;
    &.showSlider {
      overflow: hidden;
      height: calc(100vh - 55px);  
    }

    .item {
      border-radius: 1rem;
      padding: .5rem;
      border: solid 1px #3c16e8;
      box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 1);
      -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 1);
    }
  }
}