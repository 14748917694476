    .review-grid {
      display: inline-grid;
      width: 100%;
      overflow: auto;
      height: 100vh;
      grid-template-columns: 48% 48%;
      grid-template-rows: auto auto;
      grid-template-areas: 
        "main main"
        "startBox endBox";
  
      .start-box {
        grid-area: startBox;
        border: solid 1px #ffffff;
        width: 100%;
        height: 50vh;
        overflow: hidden;
        img {
          width: auto;
          height: 50vh;
        }
      }
    
      .main {
        grid-area: main;
        border: solid 1px #ffffff;
        overflow: hidden;
        width: 100%;
        background-color: #3a3d42;
          .item1 {
            grid-area: item1;
            z-index: 0;
            display: none;
            img {
              width: auto;
              height: 50vh;  
              border-right: solid 1px #ffffff;
  
              @media (orientation: portrait) {
                display: none;
              }
            }
          }
          .item2 {
            grid-area: item2;
            display: block;
            width: 100%;
            .content {
              position: relative;
              left: 0px;
              p {
                font-size: 1rem;
                line-height: 1.3rem;
                font-style: italic;
              }
              .svg-inline--fa {
                font-size: 2.3rem;
                margin-bottom: .3rem;
              }
              .w100 {
                display: block;
              }
              h2 {
                font-weight: 700;
                margin-bottom: 0;
              }
              h3 {
                font-size: 1.3rem;
              }
              .arm {
                margin-top: 1rem;
                font-size: 2rem;
              }
            }
          }
      }
    
      .end-box {
        grid-area: endBox;
        border: solid 1px #ffffff;
  
        width: 100%;
        width: 100%;
        height: 50vh;
        overflow: hidden;
        img {
          width: auto;
          height: 50vh;
        }
      }
      @media screen and (min-width: 750px) {
        width: 100%;
        height: 50vh;
        column-gap: 10px;
  
        // display: grid;
        grid-template-columns: 14% 69% 14%;
        grid-template-rows: 50vh;
        justify-items: center;
        justify-content: space-between;
        grid-template-areas: 
          "startBox main endBox"; 
        
        .main {
          display: inline-grid;
          grid-template-columns: auto 75%;
          grid-template-rows: 50vh;
          grid-template-areas: 
            "item1 item2";  
          .item2 {
            display: flex;
            flex-direction: column;
            justify-content: center;    
            .content {
              .w100 {
                display: inline-flex;
              }
      
            }
            .arm {
              font-size: 5rem;
            }  
          }
          .item1 {
            display: block;
          }
        }
      }
    }