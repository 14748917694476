#root { overflow: hidden }
.App {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #020202;
  min-height: 100vh;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  overflow: hidden;
  .body {
    position: relative;
    width: 100%;
  }

  h1 {
    line-height: 3rem;
    font-weight: 900;
    margin-bottom: 1rem;
  }
  a {
    font-size: 2rem;
    font-weight: 900;
  }
  p {
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: justify;
  }
  li {
    font-size: 1rem;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.CMS {
  .header {
    height: 111px;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    
    box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 1);
    a .logo {
      position: relative;
      left: 15px;
      top: 5px;
      width: 9rem;
      @media (min-width:750px) {
        width: 13rem;
      }
    }
    .menu {
      a .logo {
        left: unset;
        top: unset;  
      }
    }
  }
  h1, h2, h3 {
    color: #ffffff;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* backgroun-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
