.text-center{
    text-align: center;
}
.whyChoose{
    .row{
        margin-bottom: 10px;
    }
}
.steps{
    .row{
        margin-bottom: 20px;
        margin-top: 20px;
    }
    h2{
        margin-bottom: 0 !important;
        margin-top: 0.5em !important;
    }
    h1{
        margin-bottom: 1em !important; 
    }

}
@media (max-width:768px) {
    .steps{
        .md{
            flex-direction: column;
            display: flex;
        }
    h2{
        margin-bottom: 0 !important;
        margin-top: 2em !important;
    }
}
}
@media (min-width:768px) {
    .ctm_mobile{
        .ctm_mobile_1{
            order: 2;
        }
        .ctm_mobile_2{
            order: 1;
        }
    }
}