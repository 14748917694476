.text-center{
    text-align: center;
}
.whyChoose{
    h1{
        margin-bottom: 10px !important;    }
    .row{
        margin-bottom: 0px !important;
    }
    p{
        text-align: justify !important;
        padding: 50px;
    }
    .ctm_p{
        text-indent: 1em !important;
    }
    img{
        width: 100% !important;
    }
}
.ctm_mobile_2{
    p{
        text-align: left;
    }
}
.whyChoose_img{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

@media (max-width:768px) {
    .whyChoose{
        img{
            margin-bottom: 10px;
        }
         .md{
        flex-direction: column;
        display: flex;
    }
    p{
        padding: 0 !important;
    }
    }
.ctm_mobile{
        .ctm_mobile_1{
            order: 1;
        }
        .ctm_mobile_2{
            order: 2;
        }
    }
}