.main-content {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 1s ease-in-out;
  opacity: 1;
  overflow: hidden;
  .w50 img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: auto;
    opacity: .5;
  }
  &.show {
    transition: all 1s ease-in-out;
    opacity: 1;
    z-index: 0;
  }
  .message {
    position: absolute;
    bottom: 6rem;
    padding-right: 2.5rem;
    overflow: auto;
    .motto {
      color: rgba(255,119,0,1);
      font-style: italic;
    }
  }
  @media screen and (min-width: 750px) {
   .w50 img {
      position: absolute;
      left: unset;
      right: 8%;
      z-index: 5;
      opacity: 1;
      border: solid 1px #ccc;
    }
   .message {
      padding-left: unset;
      bottom: unset;
      position: absolute;
      z-index: 7;
      top: 42%;
      transform: translateY(-42%);
      padding-right: 2.5rem;
      overflow: auto;  
    }
  }
}
.slider-container{
  width: 100%;
  height: 100%;
}
.slider_main_img, .slide.active video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slide.active{
  width: 100%;
  position: relative;
  height: 100%;
  object-fit: cover;
}
.prvButton_slider{
  position: absolute;
  top: 44%;
  transform: rotate(180deg);
  left: 1%;
  z-index: 9999 !important;
  width: 45px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  img{
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.fade-enter-active {
    animation: fadeIn 0.5s ease-in-out forwards;
  }

  &.fade-exit-active {
    animation: fadeOut 0.5s ease-in-out forwards;
  }
}
.nextButton_slider{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1%;
    z-index: 9999 !important;
    width: 45px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  img{
    width: 100%;
  }
}
.pausebtn_slider{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    left: 50%;
  img{
    width: 100%;
  }
}
.call-to-action_slider{
  top: 80%;
  transform: translate(-50%, -50%);
  left: 50%;
  position: absolute;
  font-size: 20px;
  border-radius: 13px;
  background: #c40509;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%);
  border-color: #4444;
  height: 46px;
  font-weight: 700;  
}
.call-to-action_slider:hover{
  color: #fff !important;
  border-color: #4444 !important;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%) !important;
}
.call-to-action_slider:focus{
  color: #fff !important;
  border-color: #4444 !important;
  background: linear-gradient(90deg, #c40509 0%, #c42e05 35%, #ff7700 100%) !important;
}